/**
 * @generated SignedSource<<e208532e2c5741edda3024be9b1a58a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Analyses_metricsSectionQuery$variables = {
  analysisHandle: string;
};
export type Analyses_metricsSectionQuery$data = {
  readonly analysis: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalysisMetricsSection_analysis">;
  } | null;
};
export type Analyses_metricsSectionQuery = {
  response: Analyses_metricsSectionQuery$data;
  variables: Analyses_metricsSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "analysisHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "analysisHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Analyses_metricsSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalysisMetricsSection_analysis"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Analyses_metricsSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisReport",
                "kind": "LinkedField",
                "name": "report",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnalysisMetric",
                    "kind": "LinkedField",
                    "name": "additionalMetrics",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grouping",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnalysisMetricRanges",
                        "kind": "LinkedField",
                        "name": "ranges",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AnalysisMetricRangesAnnotation",
                            "kind": "LinkedField",
                            "name": "annotations",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "severity",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "min",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "max",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "format",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eba3a8cbecd3a471ad060bb945fc2c1a",
    "id": null,
    "metadata": {},
    "name": "Analyses_metricsSectionQuery",
    "operationKind": "query",
    "text": "query Analyses_metricsSectionQuery(\n  $analysisHandle: String!\n) {\n  analysis(handle: $analysisHandle) {\n    ...AnalysisMetricsSection_analysis\n    id\n  }\n}\n\nfragment AnalysisMetricKeyValue_metric on AnalysisMetric {\n  label\n  description\n  ranges {\n    __typename\n  }\n  ...AnalysisMetricRangesTable_metric\n  ...AnalysisMetricRangesNumberLine_metric\n  ...AnalysisMetricValue_metric\n}\n\nfragment AnalysisMetricRangesNumberLine_metric on AnalysisMetric {\n  value\n  ranges {\n    annotations {\n      label\n      severity\n      min\n      max\n    }\n  }\n}\n\nfragment AnalysisMetricRangesTable_metric on AnalysisMetric {\n  value\n  ranges {\n    annotations {\n      label\n      severity\n      min\n      max\n    }\n  }\n}\n\nfragment AnalysisMetricValue_metric on AnalysisMetric {\n  value\n  ranges {\n    annotations {\n      severity\n      min\n      max\n    }\n  }\n  unit\n  format\n}\n\nfragment AnalysisMetricsSection_analysis on Analysis {\n  data {\n    report {\n      additionalMetrics {\n        grouping\n        ...AnalysisMetricKeyValue_metric\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "864d49ced1380df0c28ec941c39b4514";

export default node;
